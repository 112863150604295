<template>
  <div
    class="flex flex-grow flex-col overflow-y-auto border-r border-neutral-300 dark:border-neutral-800 bg-white dark:bg-neutral-900"
  >
    <div class="pt-16 flex flex-grow flex-col">
      <nav class="flex-1 space-y-1 px-2 pt-2" aria-label="Sidebar">
        <template v-for="item in nav.items" :key="item.name">
          <div v-if="!item.children">
            <TheNuxtLink
              :to="item.href"
              @click="() => item.href && $emit('changeNav', item.href!)"
              active-class="bg-neutral-200 text-neutral-900 dark:bg-neutral-800 dark:text-neutral-100 dark:text-neutral-100"
              class="hover:bg-neutral-100 dark:hover:!bg-neutral-800 text-neutral-500 hover:text-neutral-700 dark:hover:!text-neutral-300 group w-full flex items-center pl-3 py-2 font-medium rounded-md"
            >
              <component
                v-if="item.icon"
                :is="item.icon"
                class="mr-3 flex-shrink-0"
                aria-hidden="true"
              />
              {{ item.name }}
            </TheNuxtLink>
          </div>
          <HeadlessDisclosure
            as="div"
            v-else
            class="space-y-1"
            :default-open="item.defaultOpen"
            v-slot="{ open }"
          >
            <HeadlessDisclosureButton
              :class="[
                open
                  ? 'text-neutral-700 dark:text-neutral-300'
                  : 'text-neutral-500',
                'hover:bg-neutral-100 hover:text-neutral-700 dark:hover:bg-neutral-800 dark:hover:text-neutral-300 group w-full flex items-center pl-3 pr-1 py-2 text-left font-medium rounded-md focus:outline-none',
              ]"
            >
              <component
                v-if="item.icon"
                :is="item.icon"
                class="mr-3 flex-shrink-0"
                aria-hidden="true"
              />
              <span class="flex-1">{{ item.name }}</span>
              <svg
                :class="[
                  open ? 'text-neutral-700 rotate-90' : 'text-neutral-500',
                  'ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-neutral-700',
                ]"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
              </svg>
            </HeadlessDisclosureButton>
            <HeadlessDisclosurePanel class="space-y-1">
              <HeadlessDisclosureButton
                v-for="subItem in item.children"
                :key="subItem.name"
                as="div"
              >
                <TheNuxtLink
                  :to="subItem.href"
                  @click="emit('changeNav', subItem.href ?? '/')"
                  active-class="bg-neutral-200 text-neutral-900 dark:bg-neutral-800 dark:text-neutral-100 dark:text-neutral-100"
                  class="hover:bg-neutral-100 dark:hover:!bg-neutral-800 text-neutral-500 hover:text-neutral-700 dark:hover:!text-neutral-300 group w-full flex items-center pl-12 pr-1 py-2 text-left font-medium rounded-md focus:outline-none"
                >
                  {{ subItem.name }}
                </TheNuxtLink>
              </HeadlessDisclosureButton>
            </HeadlessDisclosurePanel>
          </HeadlessDisclosure>
        </template>
      </nav>
    </div>

    <footer
      class="flex items-center justify-center p-4 border-t border-neutral-300 dark:border-neutral-800 dark:text-neutral-100"
    >
      <div class="">&copy; {{ new Date().getFullYear() }} NamiComi</div>
    </footer>
  </div>
</template>

<script setup lang="ts">
import type { NestedNavigationItem } from "~/types/navigation";

export type NavItem = {
  current?: boolean;
} & NestedNavigationItem;

export interface Props {
  nav: {
    site: string;
    items: NavItem[];
  };
}

interface Events {
  (e: "changeNav", v: string): void;
}

defineProps<Props>();
const emit = defineEmits<Events>();
</script>
