<template>
  <div class="text-neutral-800 dark:text-neutral-200">
    <TheDesktopNavbarTop
      :siteName="t('layouts.admin.site')"
      class="fixed top-0 left-0 z-40 transition-transform shadow"
      :class="{
        '-translate-y-full sm:translate-y-0': scrollDirection === 'downward',
      }"
    />

    <TheMobileNavbarBottom
      @openNavbar="navbarOpen = true"
      class="fixed bottom-0 left-0 z-40 transition-transform"
      :class="{
        'translate-y-full sm:translate-y-0': scrollDirection === 'downward',
      }"
      :nav="[
        {
          id: 'dashboard',
          name: t('layouts.admin.dashboard'),
          href: href.admin.dashboard,
          icon: IconLayoutDashboard,
        },
        {
          id: 'dashboard',
          name: t('layouts.admin.approval'),
          href: href.admin.approval.titles,
          icon: IconEyeCheck,
        },
        {
          id: 'reports',
          name: t('layouts.admin.reports'),
          href: href.admin.reports,
          icon: IconSpeakerphone,
        },
        {
          id: 'menu',
          name: t('layouts.default.menu'),
          href: '#',
          icon: IconMenu2,
        },
      ]"
    />

    <div class="bg-neutral-100 dark:bg-black min-h-[100dvh]">
      <template v-if="!showAdmin">
        <NamiLoginPrompt v-if="!authStore?.userEntity" />
        <div
          v-else
          class="flex flex-col space-y-6 items-center justify-center z-50 h-80 px-4 text-center"
        >
          You do not have access to the admin panel.
        </div>
      </template>
      <template v-else>
        <TheDesktopSidebarLeft
          class="hidden sm:fixed z-10 top-0 left-0 sm:inset-y-0 sm:flex sm:w-64 sm:flex-col"
          @changeNav="changeNav"
          :nav="nav"
        />

        <div class="sm:pl-64 pt-16 min-h-[100dvh]">
          <main class="sm:px-4 lg:px-6 px-2 pt-4 pb-20 sm:py-4 lg:py-6">
            <slot />
          </main>
        </div>
      </template>
    </div>

    <TheMobileExpandableSidebarLeft
      v-model="navbarOpen"
      @changeNav="changeNav"
      :nav="nav"
    />
  </div>
</template>

<script setup lang="ts">
import TheDesktopSidebarLeft, {
  type Props as DesktopNavProps,
} from "~~/components/the/TheDesktopSidebarLeft.vue";
import TheDesktopNavbarTop from "~~/components/the/TheDesktopNavbarTop.vue";
import TheMobileExpandableSidebarLeft from "~~/components/the/TheMobileExpandableSidebarLeft.vue";
import {
  IconEyeCheck,
  IconLayoutDashboard,
  IconMenu2,
  IconSearch,
  IconSpeakerphone,
  IconAward,
  IconChess,
  IconCloudComputing,
  IconHome,
  IconCurrencyDollar,
  IconNotes,
} from "@tabler/icons-vue";
import { IconGraph } from "@tabler/icons-vue";

const nuxtApp = useNuxtApp();
const href = useHref();
const scrollDirection = useScrollDirection();

const { t } = nuxtApp.$i18n.global;

// The stores are undefined on the server
const authStore = nuxtApp.$auth();

const authenticatedUser = computed(() => authStore?.user ?? null);
const navbarOpen = ref(false);
const showAdmin = computed(() => hasAdminAccess(authenticatedUser.value));

const nav = ref<DesktopNavProps["nav"]>({
  site: t("layouts.admin.site"),
  items: [
    {
      name: t("layouts.admin.dashboard"),
      icon: IconLayoutDashboard,
      href: href.value.admin.dashboard,
    },
    {
      name: t("layouts.admin.search"),
      icon: IconSearch,
      defaultOpen: true,
      children: [
        {
          name: t("layouts.admin.titles"),
          href: href.value.admin.titles,
        },
        {
          name: t("layouts.admin.chapters"),
          href: href.value.admin.chapters,
        },
        {
          name: t("layouts.admin.organizations"),
          href: href.value.admin.organizations,
        },
        {
          name: t("layouts.admin.users"),
          href: href.value.admin.users,
        },
      ],
    },
    {
      name: t("layouts.admin.approval"),
      icon: IconEyeCheck,
      defaultOpen: true,
      children: [
        {
          name: t("layouts.admin.titles"),
          href: href.value.admin.approval.titles,
        },
        {
          name: t("layouts.admin.chapters"),
          href: href.value.admin.approval.chapters,
        },
      ],
    },
    {
      name: t("layouts.admin.reports"),
      defaultOpen: true,
      icon: IconCurrencyDollar,
      children: [
        {
          name: t("layouts.admin.payouts"),
          href: href.value.admin.payments.payouts,
        },
        {
          name: t("layouts.admin.ledger"),
          href: href.value.admin.payments.ledger,
        },
        {
          name: t("layouts.admin.accounts"),
          href: href.value.admin.payments.accounts,
        },
        {
          name: t("layouts.admin.balance"),
          href: href.value.admin.payments.balance,
        },
      ],
    },
    {
      name: t("layouts.admin.gamification"),
      icon: IconChess,
      defaultOpen: true,
      children: [
        {
          name: t("layouts.admin.achievements"),
          href: href.value.admin.engagement.achievements.index,
        },
        {
          name: t("layouts.admin.badges"),
          href: href.value.admin.engagement.badges.index,
        },
        {
          name: t("layouts.admin.emotes"),
          href: href.value.admin.engagement.emotes.index,
        },
        {
          name: t("layouts.admin.stickers"),
          href: href.value.admin.engagement.stickers.index,
        },
        {
          name: t("layouts.admin.frames"),
          href: href.value.admin.engagement.frames.index,
        },
        {
          name: t("layouts.admin.slots"),
          href: href.value.admin.engagement.slots.index,
        },
      ],
    },
    {
      name: t("layouts.admin.reports"),
      icon: IconSpeakerphone,
      href: href.value.admin.reports,
    },
    {
      name: t("layouts.admin.competitions"),
      icon: IconAward,
      href: href.value.admin.competitions,
    },
    {
      name: t("layouts.admin.homepage"),
      icon: IconHome,
      defaultOpen: true,
      children: [
        {
          name: t("layouts.admin.homepage_children.assets"),
          href: href.value.admin.homepage.assets,
        },
        {
          name: t("layouts.admin.homepage_children.carousels"),
          href: href.value.admin.homepage.carousels,
        },
        {
          name: t("layouts.admin.homepage_children.staff_picks"),
          href: href.value.admin.homepage.staffPicks,
        },
      ],
    },
    {
      name: t("layouts.admin.distribution"),
      icon: IconCloudComputing,
      defaultOpen: true,
      children: [
        {
          name: t("layouts.admin.distributions"),
          href: href.value.admin.distribution.index,
        },
        {
          name: t("layouts.admin.distribution_jobs"),
          href: href.value.admin.distribution.jobs,
        },
      ],
    },
    {
      name: t("layouts.admin.stats"),
      icon: IconGraph,
      defaultOpen: true,
      children: [
        {
          name: t("layouts.admin.stats_children.creators"),
          href: href.value.admin.stats.creators,
        },
        {
          name: t("layouts.admin.stats_children.users"),
          href: href.value.admin.stats.users,
        },
      ],
    },
    {
      name: t("layouts.admin.logs"),
      href: href.value.admin.logs,
      icon: IconNotes,
    },
  ],
});

function changeNav() {
  navbarOpen.value = false;
}
</script>
